import type { Dispatch, SetStateAction } from "react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  PageWrapper,
  PageHeader,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../layout/portalPageLayout";
import type { SEODetail } from "../../../types/types";

import { ErrorPlaceholder } from "../../../components/Error";
import type { ErrorWithStatus } from "../../../util/util";
import {
  PIM_PRODUCT_DETAILS_PORTFOLIO_TAB,
  useStoreState,
} from "../../../util/util";

import { useTranslation } from "react-i18next";
import type { PIMProduct } from "../../../types/types.PIM";
import { Auth } from "../../../components/Auth";
import { ProductTabs } from "../../SharedPages/ProductDetailPage/util";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { SEOHelmet } from "../../../components/SEOHelmet/SEOHelmet";
import type { ProductMutate } from "../../SharedPages/ProductDetailPage/ProductDetailPage";
import { ProductDetailsTransactionsSection } from "./ProductDetailsTransactionsButtons";
import { Tabs } from "@reach/tabs";
import { Modal } from "../../../components/Modal/Modal";
import { NumberParam, useQueryParams } from "use-query-params";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { useRoutePath } from "../../../util/Routing";
import { helmetJsonLdProp } from "react-schemaorg";
import type { Product } from "schema-dts";

const ProductHeader = styled(PageHeader)`
  align-items: flex-start;
  justify-content: flex-start;
`;

const ProductHeaderImage = styled.img`
  min-width: 100px;
  max-width: 200px;
  max-height: 150px;
  margin-right: 15px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  padding: 1px;
  align-self: center;
  cursor: pointer;
`;

const ProductHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0;
  h1 {
    margin: 0px 5px 2px 0;
  }
`;

const ModalWrapper = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProductDetails {
  product: PIMProduct | undefined;
  mutateProduct: ProductMutate;
  error: ErrorWithStatus | undefined;
  updateListHeight?: Dispatch<SetStateAction<number | undefined>>;
  setbackToProductList?: Dispatch<SetStateAction<boolean>>;
  can_set_tab_query?: boolean;
}
/**
 * Portfolio Product detail page
 */
export function PIMProductDetailsPortfolio({
  product,
  mutateProduct,
  error,
  updateListHeight,
  setbackToProductList,
  can_set_tab_query = true,
}: IProductDetails) {
  const { hasPermission, roleIsGuest, roleIsSomeKindOfBuyer } =
    useContext(Auth);

  const { t } = useTranslation();
  const { storefront_metadata } = useStoreState();
  const { pathname } = useLocation();
  const history = useHistory();
  const { storePath } = useRoutePath();
  const portfolioView = sessionStorage.getItem("portfolioView");
  const [showProductImageModal, setShowProductImageModal] = useState(false);
  const [fullSizeProductImage, setFullSizeProductImage] = useState(false);
  const [query, setQuery] = useQueryParams({ tab: NumberParam });
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    // we don't know the length of the tabs that the buyer will see, so when the
    // product changes reset to the first tab, because there will always be 1.
    const tab =
      query?.tab ??
      (localStorage.getItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB)
        ? Number(localStorage.getItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB))
        : 0);
    setCurrentTab(tab);
  }, [query]);

  const changeTab = (index: number) => {
    if (updateListHeight) {
      updateListHeight(index);
    }
    setCurrentTab(index);
    if (can_set_tab_query) {
      setQuery({ tab: index });
    } else {
      localStorage.setItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB, `${index}`);
    }
  };

  useEffect(() => {
    if (roleIsSomeKindOfBuyer) {
      if (portfolioView === "modern" || portfolioView === "classic") {
        const basePath = `${storePath}/admin/pim/products`;
        const orderId = pathname.split("/").pop();
        history.replace(`${basePath}/${orderId}`);
      }
    }
  }, [history, roleIsSomeKindOfBuyer, portfolioView, storePath, pathname]);

  if (!product) {
    // Product data is still loading.
    return <DelayedSpinner />;
  }

  const productCoverImage = product.cover_image?.signed_url;

  const seoDetails = (): SEODetail => {
    const seoGroupSummary = product.product_schema.groups.find(
      (group) => group.name.toLowerCase() === "seo"
    );

    const seoDataType: Record<string, string> = {};

    seoGroupSummary?.attributes?.forEach(
      ({ object_type, values }) =>
        (seoDataType[object_type] = (values?.[0]?.value as string) ?? "")
    );

    return {
      meta_description: seoDataType?.seo_meta_description ?? null,
      meta_keywords: seoDataType?.seo_meta_keywords ?? null,
      meta_title: seoDataType?.seo_meta_title ?? null,
      page_title: seoDataType?.seo_page_title ?? null,
      og_title: seoDataType?.seo_og_title ?? null,
      og_type: seoDataType?.seo_og_type ?? null,
      og_image: seoDataType?.seo_og_image ?? null,
      og_description: seoDataType?.seo_og_description ?? null,
      og_locale: seoDataType?.seo_og_locale ?? null,
      og_url: seoDataType?.seo_og_url ?? null,
    };
  };

  if (error) {
    return (
      <ErrorPlaceholder
        message={error.message ?? t("There was an error loading the product.")}
      />
    );
  }

  return (
    <PageWrapper style={{ position: "relative" }}>
      <Helmet
        script={
          storefront_metadata?.tenant_name
            ?.toLowerCase()
            .split(" ")
            .join("_") === "cj_chemicals"
            ? [
                helmetJsonLdProp<Product>({
                  "@context": "https://schema.org",
                  "@type": "Product",
                  name: "Glacial Acrylic Acid",
                  description:
                    "Glacial Acrylic Acid the simplest unsaturated carboxylic acid is a colorless liquid. Glacial Acrylic Acid is used as an additive in paints, lacquers, coatings, adhesives, dispersants, thickeners, adhesives, inks, lubricants, floor polish, plastics, textile, leather, paper finishes, hair styling and finishing products. It is also a feedstock for many other acrylic polymers, acrylates, and co-polymers.",
                  url: "https://portal.cjchemicals.net/store/cj-chemicals/product/glacial-acrylic-acid",
                  image: "",
                  brand: {
                    "@type": "Brand",
                    name: "CJ Chemicals",
                  },
                  sku: "Drums, Totes, Bulk",

                  additionalProperty: [
                    {
                      "@type": "PropertyValue",
                      name: "Molecular Formula",
                      value: "C3H4O2",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "CAS Number",
                      value: "79-10-7",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "Concentration",
                      value: "99.5% Purity",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "Boiling Point",
                      value: "141°C",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "Density",
                      value: "1.051 g/cm³",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "Applications",
                      value:
                        "Ideal for high-performance coatings and adhesives.",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "Safety Data Sheet",
                      value:
                        "https://portal.cjchemicals.net/api/v2/tenants/d1d1e976-3f1e-4a93-ac2f-cd1e718ba339/pim/assets/fd81f925-6580-4e2f-b0ec-b6a2093185eb/download",
                    },
                    {
                      "@type": "PropertyValue",
                      name: "Technical Data Sheet",
                      value: "",
                    },
                  ],
                  offers: {
                    "@type": "Offer",
                    price: "Request Quote",
                    priceCurrency: "USD",
                    availability: "https://schema.org/InStock",
                  },
                  isRelatedTo: {
                    "@type": "Product",
                    name: "Acrylic Acid",
                    url: "https://portal.cjchemicals.net/store/cj-chemicals/product/acrylic-acid",
                  },
                }),
              ]
            : []
        }
      >
        <meta property="og:title" content={product.name} />
        {product.image_url && (
          <meta property="og:image" content={product.image_url} />
        )}
        <meta
          property="og:description"
          content={`Check out ${product.name} ${
            product.produced_by ? `by ${product.produced_by}` : ``
          } 
    on the ${storefront_metadata.tenant_name} portfolio`}
        />
      </Helmet>
      {seoDetails() && (
        <SEOHelmet
          seo={seoDetails()}
          extraProps={{
            slug: product.name,
            image_url: product.image_url,
          }}
        />
      )}
      <ProductHeader>
        {productCoverImage && (
          <ProductHeaderImage
            itemProp="image"
            src={productCoverImage}
            alt={product.name}
            onClick={() => setShowProductImageModal(true)}
          />
        )}
        <ProductHeaderContainer>
          <PageTitle itemProp="name">{product.name}</PageTitle>
          <ProductDetailsTransactionsSection
            product={product}
            setbackToProductList={setbackToProductList}
          />
        </ProductHeaderContainer>
      </ProductHeader>

      <FullWidthHorizontalSeparator margin={"56px 0 0"} />
      {product && (
        <Tabs
          onChange={changeTab}
          style={{ marginTop: "8px" }}
          index={currentTab}
          key={product.id}
          defaultIndex={0}
        >
          {ProductTabs({
            product,
            t,
            refreshKey: currentTab,
            mutate: mutateProduct,
            hasPermission,
            replaceProductId: () => Promise.resolve(),
            isPortfolio: true,
            roleIsGuest,
            roleIsBuyer: roleIsSomeKindOfBuyer,
          })}
        </Tabs>
      )}

      {showProductImageModal && productCoverImage && (
        <Modal
          show={showProductImageModal}
          overlay={true}
          closeModal={() => setShowProductImageModal(false)}
          modalWidth="100%"
        >
          <ModalWrapper>
            <img
              src={productCoverImage}
              alt={product.name}
              style={{
                maxWidth: fullSizeProductImage ? "auto" : "100%",
                maxHeight: fullSizeProductImage ? "auto" : "100%",
                cursor: fullSizeProductImage ? "zoom-out" : "zoom-in",
              }}
              onClick={() => setFullSizeProductImage((current) => !current)}
            />
          </ModalWrapper>
        </Modal>
      )}
    </PageWrapper>
  );
}
