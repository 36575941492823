import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled, { ThemeContext } from "styled-components";
import { Auth, useAuthContext } from "../../../../../components/Auth";
import {
  ListGridToggleButton,
  PrimaryButtonWithPlusIcon,
  TertiaryTextButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import { EditDeleteModal } from "../../../../../components/EditDeleteModal/EditDeleteModal";
import {
  DeleteIcon,
  EditIcon,
  InfoIcon,
  LoadingIcon,
  LockIcon,
  NonVisibleIcon,
  UnLockIcon,
  VisibleIcon,
} from "../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { H3, SmallText } from "../../../../../components/Typography/Typography";
import { endpoints } from "../../../../../endpoints";
import type {
  DataMutate,
  IPagination,
  Language,
  WithPagination,
} from "../../../../../types/types";
import { isChipTypeArray } from "../../../../../types/types";
import type {
  Assets,
  GridItemProps,
  PIMProduct,
  SupportedAssetCategoryType,
} from "../../../../../types/types.PIM";
import {
  formatDate,
  isAxiosError,
  TablePlaceholder,
  TablePlaceholderWrapper,
  useStoreState,
  useSupportedLanguages,
} from "../../../../../util/util";
import { EditAssetForm } from "../EditAssetForm";
import { AddAssetsForm } from "./AddAssetsForm";
import { RemoveAssetFromProduct } from "./RemoveAssetFromProduct";

import { stringify } from "querystring";
import type { Row as RowType } from "react-table";
import useSWR from "swr";
import type { DecodedValueMap, QueryParamConfigMap } from "use-query-params";
import { ArrayParam, useQueryParams } from "use-query-params";
import type { ChipType } from "../../../../../components/Chips/Chips";
import { FilterBy } from "../../../../../components/FilterBy/FilterBy";
import { chipsToStringArray } from "../../../../../components/Filters/Filters";
import { Row } from "../../../../../components/Layout/Layout";
import { WithPermission } from "../../../../../components/WithPermission/WithPermission";
import { Flex1 } from "../../../../../layout/FormLayout";
import { getCountryOption } from "../../../../../util/location";
import { useCountriesList } from "../../../../../util/Locations";
import { GridAssetView } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/GridAssetView";
import { getLanguageOptionFromSupportedLanguage } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import { EditOrGenerateProductDocument } from "../EditOrGenerateProductDocuments/EditOrGenerateProductDocument";
import { AssetNameIcon, DocPortfolioName } from "../util";

const TableContainer = styled.div`
  margin-bottom: 24px;
  & div[class*="TableWrapper"] {
    overflow: visible;
    td {
      overflow: visible;
    }
  }
`;

const TableFiltersWrapper = styled(Flex1)`
  border-left: ${({ theme }) => `4px solid ${theme.secondaryBorder}`};
  border-top: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  border-right: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  padding: 5px 10px;
`;

const ScrollTableWrapper = styled.div`
  max-height: 348px;
  position: relative;
  overflow: auto;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  border-left: 0;
  table th {
    border-top: 0;
    position: sticky;
    top: 0;
  }
  table {
    border: 0;
  }

  table thead td:last-child {
    border-bottom: 0;
  }
  ${TablePlaceholderWrapper} {
    border-bottom: 0;
    border-right: 0;
  }
`;

const ScrollGridWrapper = styled.div`
  max-height: 670px;
  position: relative;
  overflow: auto;
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
`;

type TableProduct = {
  id: string | boolean;
  name: string;
  expiration_date: string;
  issue_date: string;
  country: string;
  region: string;
  document_type: string;
  asset_type: string;
  category: {
    id: string;
    name: SupportedAssetCategoryType;
  };
  language: string;
};

type TableData = (TableProduct & {
  info: {
    modified_at: string;
    modified_by: string;
    number_of_products: number;
    renewal_date: string;
  };
  country_region: string;
} & {
    [Prop in keyof Assets]+?: string | number | boolean | ChipType | object;
  })[];

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

export const DocumentsOrDigitalMediaTable = ({
  product,
  title,
  fetchProductData,
  replaceProductId,
  isOutsideLogin = false,
}: {
  title: string;
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  isOutsideLogin?: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [assetData, setAssetData] = useState<Assets[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [offset, setOffset] = useState<number>(0);
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [query, setQuery] = useQueryParams(
    title === "Documents"
      ? {
          document_country: ArrayParam,
          document_region: ArrayParam,
          document_type: ArrayParam,
          document_language: ArrayParam,
          document_category: ArrayParam,
          asset_type: ArrayParam,
        }
      : {
          media_country: ArrayParam,
          media_region: ArrayParam,
          media_document_type: ArrayParam,
          asset_type: ArrayParam,
          media_language: ArrayParam,
          media_category: ArrayParam,
        }
  );
  const countries = useCountriesList();
  const { tenant_id, is_publishing_product, storefront_id } = useStoreState();
  const { hasPermission, roleIsGuest, roleIsSellerAdmin } = useAuthContext();

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { roleIsSomeKindOfSeller } = useContext(Auth);
  const requestLimit = 20;
  const [showSlideOut, setSlideOut] =
    useState<"add" | "edit" | "delete" | "generate" | false>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editRowData, setEditRowData] = useState<Assets | null>(null);
  const [deleteRowData, setDeleteRowData] = useState<Assets | null>(null);
  const [list_grid_toggle, set_list_grid_toggle] =
    useState<"list" | "grid">("list");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { supported_languages } = useSupportedLanguages();

  const product_document_ref = useRef<{ clear_poll_timeout: () => void }>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const closeSlideOut = () => setSlideOut(false);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);

  const [tableData, setTableData] = useState<TableProduct[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const productStatus = useMemo(() => product.status, [product.status]);

  const [categoryTypes, setCategoryTypes] = useState<string[]>([]);

  const [assetTypes, setAssetTypes] = useState<string[]>([]);

  const [countryFilters, setCountryFilters] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);

  const [documentTypesFilters, setDocumentTypesFilters] = useState<string[]>(
    []
  );
  const [languagesFilters, setLanguagesFilters] = useState<string[]>([]);

  const assetQueryAsParam = (params: DecodedValueMap<QueryParamConfigMap>) => {
    const result: DecodedValueMap<QueryParamConfigMap> = {};

    Object.keys(params).forEach((key) => {
      const val = params[key];

      if (val !== undefined && val !== null && val !== "") {
        if (key.includes("_category")) {
          result["asset_category"] = val;
        } else if (key.includes("asset_type")) {
          result["asset_type"] = val;
        } else if (key.includes("document_type")) {
          result["document_type"] = val;
        } else {
          result[key.split("_")[1]] = val;
        }
      }
    });

    return stringify(result) ? `&${stringify(result)}` : ``;
  };

  const {
    data: assets,
    error: assetsError,
    mutate: mutateAssets,
    isValidating: isValidatingAssets,
  } = useSWR<WithPagination<{ data: Assets[] }>, AxiosError>(
    `/v2/tenants/${tenant_id}/pim/products/${product.id}/assets?${
      title === "Documents"
        ? "asset_type=document"
        : title === "Digital Media" && !query.asset_type
        ? "asset_type=image&asset_type=video"
        : ""
    }${assetQueryAsParam(
      query
    )}&limit=${requestLimit}&show_generated_assets=true&offset=${offset}`
  );

  const {
    data: assetFilters,
    mutate: mutateAssetFilters,
    isValidating: isValidatingAssetFilters,
  } = useSWR<
    {
      asset_category: string[];
      asset_type: string[];
      country: string[];
      document_type: string[];
      language: string[];
      region: string[];
    },
    AxiosError
  >(
    `/v2/tenants/${tenant_id}/pim/products/${product.id}/assets/filters?${
      title === "Documents"
        ? "asset_type=document"
        : title === "Digital Media" && !query.asset_type
        ? "asset_type=image&asset_type=video"
        : ""
    }${assetQueryAsParam(query)}${
      activeFilter?.length > 0 ? `&active_filter=${activeFilter}` : ``
    }`,
    {
      onError: () => {
        notifyError(t("could not load asset filters, something went wrong."));
      },
    }
  );

  const [categoryTypesFromURL, setCategoryTypesFromURL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_category
        ? query?.document_category
        : title === "Digital Media" && query?.media_category
        ? query?.media_category
        : []) as string[]
    )
  );

  const [mediaTypesFromURL, setMediaTypesFromURL] = useState(
    getObjArrayFromStringArray((query.asset_type ?? []) as string[])
  );

  const [docTypesFromURL, setDocTypesFromURL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents"
        ? query?.document_type
        : query?.media_document_type) as string[]
    ) || []
  );

  const [countriesFromURL, setCountriesFromURLL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_country
        ? query?.document_country?.map(
            (country: string) =>
              countries.find((c) => c.value === country)?.label ?? country
          )
        : title === "Digital Media" && query?.media_country
        ? query?.media_country?.map(
            (country: string) =>
              countries.find((c) => c.value === country)?.label ?? country
          )
        : []) as string[]
    )
  );

  const [regionsFromURL, setRegionsFromURLL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_region
        ? query?.document_region
        : title === "Digital Media" && query?.media_region
        ? query?.media_region
        : []) as string[]
    )
  );
  const [languagesFromURL, setLanguagesFromURLL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_language
        ? query?.document_language
        : title === "Digital Media" && query?.media_language
        ? query?.media_language
        : []) as string[]
    )
  );

  function getObjArrayFromStringArray(strArr: string[]) {
    return strArr?.map((name) => ({ name }));
  }

  const { data: assetLanguages } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onError: () => {
      notifyError(t("could not load asset languages, something went wrong."));
    },
    revalidateOnMount: false,
  });

  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: {
              asset_type,
              signed_url,
              is_downloadable,
              is_generated,
              content_type,
            },
          },
        }: {
          value: string;
          row: {
            original: Assets;
          };
        }) => (
          <AssetNameIcon
            asset_type={asset_type}
            signed_url={signed_url}
            name={value}
            is_downloadable={is_downloadable}
            is_generated={is_generated}
            content_type={content_type}
          />
        ),
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
        Cell: ({
          value,
          row: {
            original: { is_downloadable },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <DocPortfolioName disabled={isOutsideLogin && !is_downloadable}>
            {value}
          </DocPortfolioName>
        ),
      },
      {
        Header: t("Language"),
        accessor: "language",
        Cell: ({
          row: {
            original: { language },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <DocPortfolioName disabled={isOutsideLogin && !language}>
            {language?.toLocaleUpperCase()}
          </DocPortfolioName>
        ),
      },
      ...(roleIsSellerAdmin &&
      (tableData.find((asset) => asset.country !== "--") ||
        tableData.find((asset) => asset.region !== "--"))
        ? [
            {
              Header: t("Country / Region"),
              accessor: "country_region",
              disableSortBy: true,
            },
          ]
        : []),
      ...(roleIsSellerAdmin &&
      tableData.find((asset) => asset.issue_date !== "--")
        ? [
            {
              Header: t("Issue Date"),
              accessor: "issue_date",
            },
          ]
        : []),
      ...(roleIsSellerAdmin &&
      tableData.find((asset) => asset.expiration_date !== "--")
        ? [
            {
              Header: t("Expiration Date"),
              accessor: "expiration_date",
            },
          ]
        : []),
      ...(roleIsSellerAdmin &&
      tableData.find((asset) => asset.document_type !== "--")
        ? [
            {
              Header: t("Document Type"),
              accessor: "document_type",
            },
          ]
        : []),
      ...(roleIsSellerAdmin
        ? [
            {
              Header: "",
              accessor: "info",
              disableSortBy: true,
              align: "right",
              Cell: ({
                row,
                value,
              }: {
                value: {
                  modified_at: string;
                  modified_by: string;
                  number_of_products: string;
                  renewal_date: string;
                };
                row: RowType<Assets>;
              }) => {
                return (
                  <div
                    data-for={`row-tooltip-${row.original.id}`}
                    data-tip={<></>}
                    style={{ display: "inline" }}
                  >
                    <InfoIcon width={20} height={20} />
                    <ReactTooltip
                      id={`row-tooltip-${row.original.id}`}
                      place="top"
                      data-html={true}
                      effect="solid"
                      multiline={true}
                    >
                      {
                        <div style={{ textAlign: "left" }}>
                          <span
                            style={{ marginBottom: "3px", display: "block" }}
                          >
                            {t("No. of Products")}: {value.number_of_products}
                          </span>
                          {value.renewal_date !== "--" && (
                            <span
                              style={{ marginBottom: "3px", display: "block" }}
                            >
                              {t("Renewal Date")}: {value.renewal_date}
                            </span>
                          )}
                          {value.modified_at !== "--" && (
                            <span
                              style={{ marginBottom: "3px", display: "block" }}
                            >
                              {t("Last Modified On")}: {value.modified_at}
                            </span>
                          )}
                          {value.modified_by !== "--" && (
                            <span
                              style={{ marginBottom: "3px", display: "block" }}
                            >
                              {t("Last Modified By")}: {value.modified_by}
                            </span>
                          )}
                        </div>
                      }
                    </ReactTooltip>
                  </div>
                );
              },
            },
          ]
        : []),
      ...(isOutsideLogin
        ? []
        : [
            {
              Header: productStatus !== "archived" ? t("Actions") : "",
              accessor: "is_downloadable",
              Cell: ({
                value,
                row: { original, index },
              }: {
                value: boolean;
                row: { original: Assets; index: number };
              }) =>
                productStatus !== "archived" && (
                  <IconWrapper>
                    {product.is_editable && (
                      <WithPermission permission="modify_assets">
                        <BtnWrapper
                          onClick={() =>
                            assetData
                              ? handleEdit(
                                  assetData.find(
                                    (asset) => asset.id === original.id
                                  ) ?? assetData[index]
                                )
                              : {}
                          }
                          disabled={is_publishing_product}
                        >
                          <EditIcon
                            fill={
                              is_publishing_product
                                ? theme.disabledButtonBG
                                : theme.primaryIconColor
                            }
                            width={20}
                            height={20}
                          />
                        </BtnWrapper>
                      </WithPermission>
                    )}
                    {title === "Documents" &&
                      (() => {
                        if (
                          hasPermission("modify_assets") &&
                          product.is_editable
                        ) {
                          if (value) {
                            return (
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                              >
                                <UnLockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : undefined
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                            );
                          } else {
                            return (
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                              >
                                <LockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : undefined
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                            );
                          }
                        } else {
                          return null;
                        }
                      })()}
                    {title === "Digital Media" &&
                      (() => {
                        if (
                          hasPermission("modify_assets") &&
                          product.is_editable
                        ) {
                          if (value) {
                            return (
                              <div
                                data-tip={
                                  original.is_cover_image
                                    ? product?.cover_image?.id === original.id
                                      ? t(
                                          "You cannot hide the product cover image"
                                        )
                                      : t(
                                          "This asset is used as a cover image for another product."
                                        )
                                    : null
                                }
                                data-for={`disabled-cover-image`}
                              >
                                <BtnWrapper
                                  disabled={original.is_cover_image}
                                  onClick={() =>
                                    handleChangeVisibility(original)
                                  }
                                >
                                  <VisibleIcon
                                    width={20}
                                    height={20}
                                    fill={
                                      original.is_cover_image
                                        ? theme.disabledLinkColor
                                        : theme.successIconColor
                                    }
                                  />
                                </BtnWrapper>
                                <ReactTooltip id={`disabled-cover-image`} />
                              </div>
                            );
                          } else {
                            return (
                              <BtnWrapper
                                onClick={() => handleChangeVisibility(original)}
                              >
                                <NonVisibleIcon
                                  width={20}
                                  height={20}
                                  fill={theme.errorColor}
                                />
                              </BtnWrapper>
                            );
                          }
                        } else return null;
                      })()}
                    {product.is_editable && (
                      <WithPermission permission="delete_assets">
                        <BtnWrapper
                          disabled={is_publishing_product}
                          onClick={() => handleDelete(original)}
                        >
                          <DeleteIcon
                            fill={
                              is_publishing_product
                                ? theme.disabledButtonBG
                                : theme.errorColor
                            }
                          />
                        </BtnWrapper>
                      </WithPermission>
                    )}
                  </IconWrapper>
                ),
            },
          ]),
    ],
    [
      t,
      isOutsideLogin,
      productStatus,
      title,
      theme.errorColor,
      theme.disabledLinkColor,
      theme.successIconColor,
      hasPermission,
      product,
      is_publishing_product,
      theme.disabledButtonBG,
      theme.primaryIconColor,
      tableData,
      assetData,
      roleIsSellerAdmin,
    ]
  );

  const handleChangeVisibility = (original: Assets) => {
    setShowEditPopup(true);
    setEditRowData(original);
  };

  const onComplete = async (productId?: string, shouldFetchData = false) => {
    if (productId) {
      replaceProductId(productId);
    } else {
      if (shouldFetchData) {
        await fetchProductData();
        mutateAssetFilters();
        mutateAssets();
      }
    }
    closeSlideOut();
  };

  const handleDelete = (original: Assets) => {
    setShowDeleteModal(true);
    setDeleteRowData(original);
  };

  const handleEdit = (original: Assets) => {
    setSlideOut("edit");
    setEditRowData(original);
  };

  const handleEditConfirm = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      const assetId = editRowData ? editRowData.id : "";
      const is_downloadable = editRowData?.is_downloadable;
      const baseURL = endpoints.v2_storefronts_id_pim_assets_id(
        tenant_id,
        assetId
      );

      await Axios.patch(baseURL, { is_downloadable: !is_downloadable });
      await fetchProductData();
      await mutateAssets();
      mutateAssetFilters();
      notifySuccess(t("Asset successfully Edited"));
      setShowEditPopup(false);
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not edit asset, Something went wrong."));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClearFilters = () => {
    setQuery(
      title === "Documents"
        ? {
            document_category: undefined,
            document_type: undefined,
            document_country: undefined,
            document_region: undefined,
            document_language: undefined,
            asset_type: undefined,
          }
        : {
            media_category: undefined,
            media_document_type: undefined,
            asset_type: undefined,
            media_country: undefined,
            media_region: undefined,
            media_language: undefined,
          },
      "replaceIn"
    );
  };

  const filterGridData = (data: Assets[]) => {
    return data.filter(
      (asset) =>
        (categoryTypesFromURL.length === 0 ||
          categoryTypesFromURL
            .map((category) => category.name)
            .indexOf(asset.asset_category) > -1) &&
        (languagesFromURL.length === 0 ||
          languagesFromURL
            .map((language) => language.name)
            .indexOf(asset.language) > -1) &&
        (regionsFromURL.length === 0 ||
          regionsFromURL.map((region) => region.name).indexOf(asset.region) >
            -1) &&
        (countriesFromURL.length === 0 ||
          countriesFromURL
            .map((country) =>
              countries?.find((c) => c.label === country.name)
                ? countries?.find((c) => c.label === country.name)?.value
                : country.name
            )
            .indexOf(asset.country) > -1) &&
        (docTypesFromURL.length === 0 ||
          docTypesFromURL
            .map((type) => type.name)
            .indexOf(asset.document_type) > -1)
    );
  };

  useEffect(() => {
    const handleProductsData = (assets: Assets[]) => {
      setTableData(
        assets.reduce<TableData>(
          (
            acc,
            {
              id,
              name,
              asset_category,
              category,
              modified_at,
              modified_by,
              is_downloadable,
              is_generated,
              asset_type,
              signed_url,
              language,
              number_of_products,
              issue_date,
              expiration_date,
              renewal_date,
              country,
              region,
              is_cover_image,
              document_type,
              content_type,
              ...rest
            }
          ) => {
            acc.push({
              id,
              asset_category,
              category,
              name: name ?? "--",
              modified_at: formatDate(modified_at),
              modified_by: modified_by ?? "--",
              is_downloadable,
              is_generated,
              asset_type,
              document_type: document_type ?? "--",
              signed_url,
              language: language?.toLocaleUpperCase(),
              issue_date: issue_date ? formatDate(issue_date, true) : "--",
              expiration_date: expiration_date
                ? formatDate(expiration_date, true)
                : "--",
              renewal_date: renewal_date
                ? formatDate(renewal_date, true)
                : "--",
              country: country ?? "--",
              country_region: `${
                getCountryOption(countries, country)?.label ?? "--"
              } / ${region ?? "--"}`,
              region: region ?? "--",
              info: {
                modified_at: formatDate(modified_at),
                modified_by: modified_by ?? "--",
                number_of_products: number_of_products,
                renewal_date: renewal_date
                  ? formatDate(renewal_date, true)
                  : "--",
              },
              is_cover_image,
              content_type: content_type ?? "application/pdf",
              ...rest,
            });
            return acc;
          },
          []
        )
      );
    };
    if (assetData) {
      handleProductsData(assetData);
    }
  }, [
    assetData,
    countries,
    categoryTypesFromURL,
    languagesFromURL,
    countriesFromURL,
    mediaTypesFromURL,
    regionsFromURL,
    docTypesFromURL,
  ]);

  useEffect(() => {
    if (assetFilters) {
      setCategoryTypes(assetFilters.asset_category);

      setAssetTypes(assetFilters.asset_type);

      setRegions(assetFilters.region);
      setCountryFilters(
        assetFilters.country
          .map((country) =>
            country ? getCountryOption(countries, country)?.label || "" : ""
          )
          .filter((val) => val)
      );

      setRegions(
        assetFilters.region
          .map((region) => region)
          .filter((val) => val !== "--")
      );
      setDocumentTypesFilters(
        assetFilters.document_type
          .map((document_type) => document_type)
          .filter((val) => val !== "--")
      );
    }
  }, [assetFilters, countries]);

  useEffect(() => {
    if (assetLanguages && assetFilters) {
      setLanguagesFilters(
        assetFilters?.language
          .map(
            (language) =>
              assetLanguages?.languages.find(
                (lang) => lang.alpha_2 === language?.toLowerCase()
              )?.name ?? "--"
          )
          .filter((val) => val !== "--")
      );
    }
  }, [assetFilters, assetLanguages]);

  useEffect(() => {
    setCategoryTypesFromURL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_category
          ? query?.document_category
          : title === "Digital Media" && query?.media_category
          ? query?.media_category
          : []) as string[]
      )
    );

    setMediaTypesFromURL(
      getObjArrayFromStringArray((query?.asset_type ?? []) as string[])
    );

    setLanguagesFromURLL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_language
          ? query?.document_language
          : title === "Digital Media" && query?.media_language
          ? query?.media_language
          : []) as string[]
      )
    );
    setCountriesFromURLL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_country
          ? query?.document_country?.map(
              (country: string) =>
                countries.find((c) => c.value === country)?.label
            )
          : title === "Digital Media" && query?.media_country
          ? query?.media_country?.map(
              (country: string) =>
                countries.find((c) => c.value === country)?.label
            )
          : []
        )?.filter((t: string) => t !== undefined) as string[]
      )
    );
    setDocTypesFromURL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_type
          ? query?.document_type
          : title === "Digital Media" && query?.media_document_type
          ? query?.media_document_type
          : []) as string[]
      )
    );
    setRegionsFromURLL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_region
          ? query?.document_region
          : title === "Digital Media" && query?.media_region
          ? query?.media_region
          : []) as string[]
      )
    );
  }, [query, title, countries]);

  // Only tds document generation is supported for now
  const hasActiveGeneratedAssetConfig =
    // despite being called `generated_assets`, this is actually a config object.
    product.product_schema.generated_assets.some(
      (genAsset) => genAsset.is_active
    );

  const canGenerateNewTDS = product.number_of_generated_assets === 0;

  const handleCategoryType = ({
    values,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    setQuery(
      title === "Documents"
        ? { document_category: chipsToStringArray(values) }
        : { media_category: chipsToStringArray(values) },
      "replaceIn"
    );
    setActiveFilter("asset_category");
  };

  const handleMediaType = ({
    values,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    setQuery({ asset_type: chipsToStringArray(values) }, "replaceIn");
    setActiveFilter("asset_type");
  };

  const handleCountryFilter = ({ values }: { values: ChipType[] }) => {
    let countryList = chipsToStringArray(values).map(
      (country) => countries.find((c) => c.label === country)?.value ?? country
    );
    setQuery(
      title === "Documents"
        ? {
            document_country: countryList,
          }
        : {
            media_country: countryList,
          },
      "replaceIn"
    );
    setActiveFilter("country");
  };

  const handleRegionFilter = ({ values }: { values: ChipType[] }) => {
    let regions = chipsToStringArray(values);
    setQuery(
      title === "Documents"
        ? { document_region: regions }
        : { media_region: regions },
      "replaceIn"
    );
    setActiveFilter("region");
  };

  const handleLanguageFilter = ({ values }: { values: ChipType[] }) => {
    let languages = chipsToStringArray(
      values.map((val) => ({
        name:
          assetLanguages?.languages.find(
            (languages) => languages.name.indexOf(val.name) === 0
          )?.alpha_2 || val.name,
      }))
    );
    setQuery(
      title === "Documents"
        ? { document_language: languages }
        : { media_language: languages },
      "replaceIn"
    );
    setActiveFilter("language");
  };

  const handleDocTypeFilter = ({ values }: { values: ChipType[] }) => {
    let docTypes = chipsToStringArray(values);
    setQuery(
      title === "Documents"
        ? { document_type: docTypes }
        : { media_document_type: docTypes },
      "replaceIn"
    );
    setActiveFilter("document_type");
  };

  useEffect(() => {
    setOffset(0);
    setIsLoadingData(true);
  }, [query]);

  useEffect(() => {
    if (assets?.data) {
      if (offset === 0) {
        setAssetData(assets?.data);
      } else {
        setAssetData((prev) => [...prev, ...assets?.data]);
      }
      setPagination(assets.pagination);
      setIsLoadingData(false);
    }
  }, [assets, offset]);
  const onScroll = () => {
    if (scrollRef.current) {
      const scrollTop = scrollRef.current.scrollTop;
      if (
        scrollRef.current.offsetHeight - scrollTop < 400 &&
        !isLoadingData &&
        ((pagination?.total && assetData.length < pagination?.total) ||
          !pagination) &&
        !isValidatingAssets
      ) {
        setIsLoadingData(true);
        setOffset(offset + requestLimit);
      }
    }
  };

  return (
    <>
      <Row>
        <div>
          <H3 style={{ marginBottom: "4px" }}>
            {title === "Documents" ? t("Documents") : t("Digital Media")}
          </H3>
          <SmallText style={{ marginBottom: "16px" }}>
            {t(`Manage {{title}} and control their accessibility`, { title })}
          </SmallText>
        </div>
        <div style={{ alignSelf: "flex-start" }}>
          <Row>
            {(isValidatingAssetFilters || isValidatingAssets) && (
              <div style={{ marginRight: "20px", marginTop: "23px" }}>
                <LoadingIcon width={22} height={22} />
              </div>
            )}
            <ListGridToggleButton
              set_toggle_state={set_list_grid_toggle}
              name={`${title}${
                isOutsideLogin ? "_portfolio" : ""
              }_list_grid_toggle`}
            />{" "}
          </Row>
        </div>
      </Row>
      {assetTypes?.length > 0 && roleIsSomeKindOfSeller && (
        <TableFiltersWrapper>
          {title === "Digital Media" && assetTypes && (
            <FilterBy
              setter={handleMediaType}
              filter={"asset_type"}
              options={getObjArrayFromStringArray(assetTypes)}
              header={t("Type")}
              label={t("Type")}
              preselectedFilters={
                isChipTypeArray(mediaTypesFromURL) ? mediaTypesFromURL : []
              }
            />
          )}
          {categoryTypes && (
            <FilterBy
              setter={handleCategoryType}
              filter={
                title === "Documents" ? "document_category" : "media_category"
              }
              options={getObjArrayFromStringArray(categoryTypes)}
              header={t("Category")}
              label={t("Category")}
              preselectedFilters={
                isChipTypeArray(categoryTypesFromURL)
                  ? categoryTypesFromURL
                  : []
              }
            />
          )}
          {assetLanguages && languagesFilters.length > 0 && (
            <FilterBy
              setter={handleLanguageFilter}
              filter="language"
              options={getObjArrayFromStringArray(languagesFilters)}
              header={t("Language")}
              label={t("Language")}
              preselectedFilters={
                isChipTypeArray(languagesFromURL)
                  ? languagesFromURL.map((lang) => ({
                      name:
                        assetLanguages.languages.find(
                          (assetLang) => assetLang.alpha_2 === lang.name
                        )?.name ?? lang.name,
                    }))
                  : []
              }
            />
          )}
          {countryFilters.length > 0 && (
            <FilterBy
              setter={handleCountryFilter}
              filter="country"
              options={getObjArrayFromStringArray(countryFilters)}
              header={t("Country")}
              label={t("Country")}
              preselectedFilters={
                isChipTypeArray(countriesFromURL) ? countriesFromURL : []
              }
            />
          )}
          {regions?.length > 0 && (
            <FilterBy
              setter={handleRegionFilter}
              filter="region"
              options={regions.map((region) => ({ name: region }))}
              header={t("Region")}
              label={t("Region")}
              preselectedFilters={
                isChipTypeArray(regionsFromURL) ? regionsFromURL : []
              }
            />
          )}

          {documentTypesFilters?.length > 0 && (
            <FilterBy
              setter={handleDocTypeFilter}
              filter="document_type"
              options={getObjArrayFromStringArray(documentTypesFilters)}
              header={t("Document Type")}
              label={t("Document Type")}
              preselectedFilters={
                isChipTypeArray(docTypesFromURL) ? docTypesFromURL : []
              }
            />
          )}
          {assetQueryAsParam(query).length > 0 && (
            <TertiaryTextButtonSmall
              onClick={() => handleClearFilters()}
              style={{ marginBottom: 0 }}
            >
              {t("Clear Filters")}
            </TertiaryTextButtonSmall>
          )}
        </TableFiltersWrapper>
      )}
      <TableContainer>
        {list_grid_toggle === "list" ? (
          <ScrollTableWrapper ref={scrollRef} onScroll={onScroll}>
            <Table
              columns={tableColumns}
              data={tableData}
              isLoading={isLoadingData}
              error={assetsError}
              lastChildleftAlign
              Placeholder={<TablePlaceholder />}
            />
          </ScrollTableWrapper>
        ) : (
          <ScrollGridWrapper ref={scrollRef} onScroll={onScroll}>
            <GridAssetView
              assets={
                filterGridData(assetData).map((asset) => ({
                  ...asset,
                  can_edit:
                    !isOutsideLogin &&
                    hasPermission("modify_assets") &&
                    productStatus !== "archived" &&
                    product.is_editable,
                  can_change_visibility:
                    !isOutsideLogin &&
                    hasPermission("modify_assets") &&
                    productStatus !== "archived" &&
                    product.is_editable,
                  can_delete:
                    !isOutsideLogin &&
                    hasPermission("delete_assets") &&
                    productStatus !== "archived" &&
                    product.is_editable,
                  can_external_download: roleIsGuest
                    ? (asset as Assets).is_downloadable
                    : true,
                  can_view_details: !isOutsideLogin,
                })) as unknown as (Assets & GridItemProps)[]
              }
              handle_asset_visibility_change={handleChangeVisibility}
              on_edit_asset={handleEdit}
              on_remove_asset={handleDelete}
              on_download_asset={(asset) => {
                window.open(asset.signed_url, "_blank");
              }}
            />
          </ScrollGridWrapper>
        )}
      </TableContainer>

      {showSlideOut === "add" && (
        <SlideOut closeFlyout={closeSlideOut} show={!!showSlideOut}>
          <AddAssetsForm
            title={title}
            onComplete={onComplete}
            product={product}
            fetchProductData={mutateAssets}
          />
        </SlideOut>
      )}

      {showSlideOut === "edit" && (
        <SlideOut
          closeFlyout={async () => {
            closeSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await fetchProductData();
            await mutateAssets();
            mutateAssetFilters();
          }}
          show={!!showSlideOut}
        >
          {editRowData?.is_generated ? (
            <EditOrGenerateProductDocument
              asset={{
                ...editRowData,
                language: getLanguageOptionFromSupportedLanguage(
                  editRowData!.language,
                  supported_languages
                ).value,
              }}
              ref={product_document_ref}
              onSuccess={onComplete}
              product={product}
            />
          ) : (
            <EditAssetForm
              onComplete={onComplete}
              fetchProductData={mutateAssets}
              product={product}
              assetData={editRowData}
              formType={title}
            />
          )}
        </SlideOut>
      )}
      {showSlideOut === "generate" && (
        <SlideOut
          closeFlyout={async () => {
            closeSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await mutateAssets();
            mutateAssetFilters();
          }}
          show={!!showSlideOut}
        >
          <EditOrGenerateProductDocument
            product={product}
            ref={product_document_ref}
            replaceProductId={replaceProductId}
            onSuccess={onComplete}
          />
        </SlideOut>
      )}
      {showEditPopup && (
        <EditDeleteModal
          title={t("Edit Asset")}
          show={showEditPopup}
          onClose={() => setShowEditPopup(false)}
          onConfirm={handleEditConfirm}
          isLoading={isSubmitting}
          confirmBtnLabel={t("Confirm")}
          hideIcon={true}
          body={
            editRowData?.is_downloadable &&
            editRowData.number_of_attribute_values > 0
              ? t(
                  "This asset will become inaccessible to users outside login. Would you like to continue?"
                )
              : t(
                  " This change will affect {{number_of_products}} other products. Would you like to continue?",
                  { number_of_products: editRowData?.number_of_products }
                )
          }
        />
      )}

      {showDeleteModal && (
        <RemoveAssetFromProduct
          onComplete={(id) => {
            onComplete(id);
            closeDeleteModal();
          }}
          product={product}
          show={showDeleteModal}
          onClose={closeDeleteModal}
          refreshAssetsList={mutateAssets}
          assetData={deleteRowData}
        />
      )}

      {hasPermission("modify_assets") &&
        productStatus !== "archived" &&
        product.is_editable && (
          <div style={{ display: "flex", gap: "16px" }}>
            <PrimaryButtonWithPlusIcon
              style={{ width: "fit-content" }}
              onClick={() => setSlideOut("add")}
            >
              {t("Add")}
            </PrimaryButtonWithPlusIcon>
            {hasActiveGeneratedAssetConfig &&
              title === "Documents" &&
              canGenerateNewTDS && (
                <PrimaryButtonWithPlusIcon
                  style={{ width: "fit-content" }}
                  onClick={() => setSlideOut("generate")}
                >
                  {t("Generate Document")}
                </PrimaryButtonWithPlusIcon>
              )}
          </div>
        )}
    </>
  );
};
